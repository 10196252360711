@font-face {
    font-family: 'atroxnormal';
    src: url(/static/media/atrox-webfont.3293a0da.woff2) format('woff2'),
         url(/static/media/atrox-webfont.ef43105f.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
.menu-text{
    font-family:'atroxnormal', Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    width: 50%;
    margin-left: -2rem;
}

@media screen and (min-width: 415px){
    .menu-text{
        font-size:50px;
    }
    .br-menu{
        display: none;
    }
    .Logo{
        height: 50px;
    }
}
@media screen and (max-width: 414px){
    .menu-text{
        font-size:17px;
    }
    .br-menu{
        display: block;
    }
    .Logo{
        display: none;
    }
    .collapse.show.navbar-collapse{
    background-image: url(/static/media/Logotiponegro.f9fddb81.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}

}

.text-jumbo{
    text-align: center;
    text-decoration: none;
}




@media screen and (min-width: 415px){
    .contact-header{
        font-size: 40px

    }
    .contact-img{
        max-width: 80%;
    }
    .contact-text{
        text-align: right;
    }
}
@media screen and (max-width: 414px){
    .contact-header{
        font-size: 25px;
        text-align: center;
    }
    .contact-img{
        max-width: 85%;
    }
    
}

.about-logo{
    text-align: center;
}
.logo{
    height: 50px;
}
.foot-line{
    font-size: 8px;
}

