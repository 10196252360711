
@media screen and (min-width: 415px){
    .contact-header{
        font-size: 40px

    }
    .contact-img{
        max-width: 80%;
    }
    .contact-text{
        text-align: right;
    }
}
@media screen and (max-width: 414px){
    .contact-header{
        font-size: 25px;
        text-align: center;
    }
    .contact-img{
        max-width: 85%;
    }
    
}
