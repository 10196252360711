@font-face {
    font-family: 'atroxnormal';
    src: url('./fonts/atrox-webfont.woff2') format('woff2'),
         url('./fonts/atrox-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
.menu-text{
    font-family:'atroxnormal', Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    width: 50%;
    margin-left: -2rem;
}

@media screen and (min-width: 415px){
    .menu-text{
        font-size:50px;
    }
    .br-menu{
        display: none;
    }
    .Logo{
        height: 50px;
    }
}
@media screen and (max-width: 414px){
    .menu-text{
        font-size:17px;
    }
    .br-menu{
        display: block;
    }
    .Logo{
        display: none;
    }
    .collapse.show.navbar-collapse{
    background-image: url('../img/Logotiponegro.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
}

}

.text-jumbo{
    text-align: center;
    text-decoration: none;
}


